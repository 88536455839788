<template>
    <div class="view checkout">
        <navbar />
        <div class="content">
            <v-container>
                <v-row class="justify-center">
                    <v-col cols="12" sm="9">
                        <router-link :to="'/admin/' + $route.params.account_id" class="back">
                            <v-icon>mdi-chevron-left</v-icon> Inapoi la contul meu
                        </router-link>
                        <div class="title">Checkout</div>
                    </v-col>
                </v-row>
                <v-row class="justify-center">
                    <v-col cols="12" sm="3">
                        <div class="subtitle" style="margin-bottom: 20px;">
                            Plan {{ plan.type }}
                        </div>
                        <div class="plan-outside" v-if="plan">
                            <plan-card :coupon="coupon.data" :data="plan" />
                        </div>
                        <div class="coupon">
                            <div class="apply" v-if="!coupon.data">
                                <p>Ai un cupon de reducere chartarium?</p>
                                <v-text-field :disabled="coupon.data" v-model="coupon.code" label="Cod cupon" flat outlined solo>
                                    <template #append>
                                        <v-btn :disabled="coupon.data" rounded  @click="applyCoupon" color="primary" type="submit" value="Aplica">
                                            Aplica Cupon
                                        </v-btn>
                                    </template>
                                </v-text-field>
                                <div style="color: #ef3f2b; margin-top: -15px; font-weight: bold;" v-if="coupon.error">
                                    Eroare: {{ coupon.error }}
                                </div>
                            </div>
                            <div class="display" v-if="coupon.data">
                                Cupon aplicat: {{ coupon.data.id }}<br>
                                Reducere aplicata: {{ coupon.data.percent_off }}%<br>
                                <div @click="cancelCoupon" class="cancel">Anuleaza cupon</div>
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <div class="subtitle">
                            Date de facturare
                        </div>
                        <div class="form">
                            <v-text-field v-model="billing.name" outlined label="Nume"></v-text-field>
                            <v-text-field v-model="billing.address.country" outlined label="Tara"></v-text-field>
                            <v-text-field v-model="billing.address.city" outlined label="Oras"></v-text-field>
                            <v-text-field v-model="billing.address.state" outlined label="Judet"></v-text-field>
                            <v-text-field v-model="billing.address.line1" outlined label="Adresa Linia 1"></v-text-field>
                            <v-text-field v-model="billing.address.line2" outlined label="Adresa Linia 2"></v-text-field>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="3">
                        <div class="subtitle">
                            Plata card
                        </div>
                        <div class="form">
                            <div class="stripe" v-if="paymentIntent">
                                <!-- <v-text-field class="card-holder-name" v-model="cardHolderName" dense placeholder="Numele de pe card" :rules="[() => !!cardHolderName || 'Campul este obligatoriu']"></v-text-field> -->
                                <div id="card-element">
                                </div><br>
                            </div>
                            <v-btn elevation="0" @click="submit" color="primary" class="submit" v-if="!loading">Plateste</v-btn>
                            <v-progress-circular model-value="20" color="primary" indeterminate v-else />
                        </div>
                        
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>
<style lang="scss">
@import "CheckoutView.scss";
</style>
<script>
import PlanCard from '@/components/molecules/PlanCard.vue';
import Navbar from "@/components/organisms/Navbar.vue";
import axios from 'axios';
export default {
    name: "CheckoutView",
    components: {
        Navbar,
        PlanCard,
    },
    data() {
        return {
            coupon: {
                code: '',
                data: null,
                error: null,
            },
            loading: true,
            plan: null,
            user: null,
            billing: {
                name: null,
                address: {
                    country: null,
                    city: null,
                    state: null,
                    line1: null,
                    line2: null,
                }
            },
            paymentMethods: null,
            stripe: window.Stripe('pk_live_51OGl4zClN2MeRDIgkFjfJfiI1sY3lzC11hNPNZ1eDBQNNlyZ8WzAXCATTM6MwdX3gsxXI9pGoPr4q9cYmMMXKz8q00i70JpNbl'),
            paymentIntent: null,
            elements: null,
            cardElement: null,
        }
    },
    async mounted() {
        await this.getCustomer();
        await this.getPlan(this.$route.query.planId);

        await this.getPaymentMethods();
        await this.getPaymentIntent();

        await this.$nextTick();

        this.elements = this.stripe.elements();
        this.cardElement = await this.elements.create('card', {
            hidePostalCode: true,
            style: {
                base: {
                    color: "#32325d",
                    fontSize: "24px"
                }
            }
        });
        this.cardElement.mount("#card-element");

        this.loading = false;


    },
    methods: {
        async cancelCoupon() {
            this.coupon = {
                code: '',
                data: null,
            }
        },
        async applyCoupon() {
            try {
                this.coupon.error = null;
                let response = await this.$axios.post('auth/checkCoupon', {
                    code: this.coupon.code,
                });
                this.coupon.data = response.data;
            } catch (error) {
                this.coupon.error = "Cod cupon invalid";
                this.$toasted.error('Cod cupon invalid');
                console.log(error);
            }
        },
        async getPaymentMethods() {
            try {
                this.paymentMethods = null;
                let response = await this.$axios.get('auth/getPaymentMethods');
                this.paymentMethods = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getPaymentIntent() {
            try {
                let response = await this.$axios.get('auth/getSetupIntent');
                this.paymentIntent = response.data.intent;
            } catch (error) {
                console.log(error);
            }
        },
        async getCustomer() {
            let response = await this.$axios.get('/accounts/' + this.$route.params.account_id + '/customer');
            this.user = response.data;
            let customer = this.user.customer;
            this.billing.name = customer.name;
            if (customer.address) {
                this.billing.address = customer.address;
            }
        },
        async getPlan(plan_id) {
            let plans = await axios.get('https://api.chartarium.ro/api/open/plans');
            plans = plans.data;
            this.plan = plans.find(e => {
                return (e.id == plan_id);
            });
        },
        async submit() {
            
            let valid = true;
    
            if(this.billing.name < 1 ||
                this.billing.address.country < 1 ||
                this.billing.address.city < 1 ||
                this.billing.address.state < 1 ||
                this.billing.address.line1 < 1
            ) {
                valid = false;
            }



            console.log('aici', valid);

            if(!valid) {
                this.$toasted.error('Te rugam completeaza toate datele de facturare');
                return;
            }


            await this.updateCustomer();
            await this.submitPayment();
        },
        async updateCustomer() {
            try {
                this.loading = true;
                let response = await this.$axios.put('/accounts/' + this.$route.params.account_id + '/customer', this.billing);
                console.log('updated', response.data);
            } catch (error) {
                this.loading = false;
                console.log(error);
                this.$toasted.error('Server error');
            }
        },
        async submitPayment() {
            this.loading = true;
            const { setupIntent, error } = await this.stripe.confirmCardSetup(
                this.paymentIntent.client_secret, {
                    payment_method: {
                        card: this.cardElement,
                    }
                }
            );
            if (error) {
                this.loading = false;
                // handle stripe card errors ?
                console.log('STIPE ERROR', error);
                if (error.message) {
                    this.$toasted.error(error.message);
                } else {
                    this.$toasted.error('Eroare la procesarea platii');
                }
                return;
            }

            try {

                let postData = {
                    payment_method: setupIntent.payment_method,
                    plan: this.plan,
                }

                // I have loaded a working coupon
                if(this.coupon.data) {
                    postData = {
                        ...postData,
                        coupon: this.coupon.data.id,
                    }
                }

                let response = await this.$axios.post('auth/subscribe', postData);
                console.log('subscribe response', response.data);

                this.loading = false;
                this.$router.push('/admin/' + this.$route.params.account_id);
                this.$toasted.success('Plata efectuata cu succes');

            } catch (error) {
                console.log(error);
                this.$toasted('Server error');
            }

        }
    }
}
</script>