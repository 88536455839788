var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"app-modal",attrs:{"persistent":"","max-width":"1300"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"app-modal__card"},[_c('div',{staticClass:"app-modal__card--close"},[_c('inline-svg',{attrs:{"src":"/img/modal-close.svg","height":"24","width":"24","fill":"#2e4dd4"},on:{"click":function($event){$event.stopPropagation();return _vm.close()}}})],1),_c('v-form',{ref:"form"},[_c('div',{staticClass:"app-modal__card--content"},[_c('h1',{staticClass:"app-modal__title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"app-modal__plan"},[_c('button-toggle',{on:{"activeToggle":function($event){_vm.visiblePlanType = $event}}}),(_vm.visiblePlanType === 1)?_c('v-item-group',{staticClass:"app-modal__plan-items",model:{value:(_vm.selectedPlan),callback:function ($$v) {_vm.selectedPlan=$$v},expression:"selectedPlan"}},_vm._l((_vm.individualPlans),function(plan){return _c('div',{key:plan.id},[_c('v-item',{attrs:{"value":plan.id,"ripple":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('div',{staticClass:"app-modal__plan-items-plan",class:{ 'app-modal__plan-items-plan--active': active, 
                                          'current': plan.id == _vm.activeplan.id || plan.id == 2,

                                 },attrs:{"ripple":""},on:{"click":toggle}},[_c('plan-card',{attrs:{"active":(plan.id == _vm.activeplan.id),"data":plan}})],1)]}}],null,true)})],1)}),0):_vm._e(),(_vm.visiblePlanType === 2)?_c('v-item-group',{staticClass:"app-modal__plan-items",model:{value:(_vm.selectedPlan),callback:function ($$v) {_vm.selectedPlan=$$v},expression:"selectedPlan"}},_vm._l((_vm.organisationPlans),function(plan){return _c('div',{key:plan.id},[_c('v-item',{attrs:{"value":plan.id,"ripple":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                 var active = ref.active;
                                 var toggle = ref.toggle;
return [_c('div',{staticClass:"app-modal__plan-items-plan",class:active ? 'app-modal__plan-items-plan--active' : '',attrs:{"ripple":""},on:{"click":toggle}},[_c('plan-card',{attrs:{"data":plan}})],1)]}}],null,true)})],1)}),0):_vm._e()],1)])]),_c('div',{staticClass:"app-modal__card--actions"},[_c('v-btn',{staticClass:"app-modal__btn",attrs:{"elevation":"4","rounded":"","color":"#fff"},on:{"click":function($event){$event.stopPropagation();return _vm.changePlan.apply(null, arguments)}}},[_c('inline-svg',{attrs:{"src":'/img/'+_vm.btnIcon+'.svg',"height":_vm.btnIconSize,"width":_vm.btnIconSize,"fill":"#2e4dd4"}}),_c('span',[_vm._v(_vm._s(_vm.btnTitle))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }