<template>
    <div class="chartarium-plan">
        <p class="chartarium-plan__title">{{data.name}}</p>
        <table class="chartarium-plan__details">
            <tbody>
                <tr>
                    <td>Useri:</td>
                    <td>{{data.max_users}}</td>
                </tr>
                <tr>
                    <td>Foldere Create:</td>
                    <td v-if="data.max_folders != 0">{{data.max_folders}}</td>
                    <td v-if="data.max_folders == 0">Nelimitat</td>
                </tr>
                <tr>
                    <td>Access Resurse:</td>
                    <td v-if="data.resource_access == 0">Limitat</td>
                    <td v-if="data.resource_access == 1">Total</td>
                </tr>
                <tr>
                    <td>Editare Resurse:</td>
                    <td>
                        <inline-svg v-if="data.resource_edit" :src="'/img/check.svg'" height="14" width="14" fill="#00B981" />
                        <inline-svg v-else :src="'/img/close-modal.svg'" height="14" width="14" fill="#F21B3F" />
                    </td>
                </tr>
                <tr :class="{'chartarium-plan__details--hidden': !data.text}">
                    <td>Personalizare cu text:</td>
                    <td>
                        <inline-svg v-if="data.edit_text" :src="'/img/check.svg'" height="14" width="14" fill="#00B981" />
                        <inline-svg v-else :src="'/img/close-modal.svg'" height="14" width="14" fill="#F21B3F" />
                    </td>
                </tr>
                <tr :class="{'chartarium-plan__details--hidden': !data.logo}">
                    <td>Personalizare cu logo:</td>
                    <td>
                        <inline-svg v-if="data.logo" :src="'/img/check.svg'" height="14" width="14" fill="#00B981" />
                        <inline-svg v-else :src="'/img/close-modal.svg'" height="14" width="14" fill="#F21B3F" />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="horizontal-dotted-divider"></div>
        <div class="price" v-if="!active">
            <p v-if="data.price==0" class="chartarium-plan__price">
                Gratis
            </p>
            <p v-if="data.price!=0" class="chartarium-plan__price"> 
                <span class="oldPrice" style="font-weight: bold;" v-if="coupon">{{data.price / 100}} RON / luna</span> 
                <span style="font-weight: bold;">{{discountPrice / 100}} RON</span> 
                <span>/luna</span>
            </p>
        </div>

        <div class="price" v-else>
            <p class="chartarium-plan__price">
                Plan Curent
            </p>
        </div>
    </div>
</template>
<style scoped lang="scss">
.chartarium-plan {
    .oldPrice {
        color: red;
        font-size: 18px;
        position: absolute;
        bottom: 80px;
        text-decoration: line-through;
        left: 0px;
        width: 100%;
        text-align: center;


    }
}
</style>
<script>
import InlineSvg from 'vue-inline-svg';
export default {
    props: ['data', 'active', 'coupon'],
    components: {
        'inline-svg': InlineSvg
    },
    data() {
        return {

        }
    },
    computed: {
        discountPrice() {
            if(!this.coupon) {
                return this.data.price;
            } else {
                let deductionPercent = this.coupon.percent_off / 100;
                let deductionAmount = this.data.price * deductionPercent;
                let discountedPrice = this.data.price - deductionAmount;
                return discountedPrice;
            }
        }
    }

}
</script>