<template>
    <div class="view subscriptions">
        <div class="account-details">
            <div class="account-details__details">
                <p class="account-details__details--title">Plan Activ</p>
            </div>
        </div>
        <div class="account-details__actions" style="margin-top: -50px;">
            <v-btn @click.stop="openPlanModal = true" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                <span>schimba plan</span>
                <v-icon color="#2e4dd4">mdi-file-document-edit-outline</v-icon>
            </v-btn>
        </div><br>
        <div class="plan">
            <div class="name">Plan {{ subscription.plan.name }} ({{ subscription.plan.type }})</div>
            <div class="input" v-if="subscription.plan.price > 0">{{ subscription.plan.price / 100 }} RON / luna</div>
            <div class="input" v-else>Gratuit</div>
            <div class="time" v-if="subscription.subscription">
                Activ de la {{ $moment(subscription.subscription.created_at).format('YYYY/MM/DD') }} </div>
            <br>
            <div @click="showCancelModal = true" class="cancel" v-if="subscription.plan.name != 'Demo'">
                Anuleaza plan
            </div>
        </div>
        <div class="modals">
            <v-dialog class="app-modal" v-model="showCancelModal" persistent max-width="620">
                <v-card class="app-modal__card">
                    <div class="app-modal__card--close">
                        <inline-svg @click.stop="showCancelModal = false" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4" />
                    </div>
                    <v-form ref="form">
                        <div class="app-modal__card--content">
                            <h1 class="app-modal__title">Anuleaza plan</h1>
                            <br>
                            <p class="app-modal__text">Esti sigur ca doresti sa anulezi acest plan?</p>
                        </div>
                    </v-form>
                    <div class="app-modal__card--actions">
                        <v-btn class="app-modal__btn app-modal__btn--cancel" elevation="4" rounded color="#fff" @click.stop="showCancelModal = false" style="margin-right: 30px;">
                            <span>Inapoi</span>
                        </v-btn>
                        <v-btn class="app-modal__btn app-modal__btn--delete" elevation="4" rounded color="#fff" @click.stop="cancelSubscription">
                            <span>Da, anulez planul</span>
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>

            <change-plan-modal :activeplan="subscription.plan" :data="user" v-model="openPlanModal" :btnIcon="'double-carret'" :btnIconSize="24" :title="'Schimbare Plan'" :btnTitle="'Schimba'" :selectedItem="data" />
        </div>
    </div>
</template>
<style lang="scss">
.view.subscriptions {
    .plan {
        .name {
            font-weight: bold;
        }

        .cancel {
            cursor: pointer;
            color: #c6443d;
        }
    }
}
</style>
<script>
import InlineSvg from 'vue-inline-svg';
import ChangePlanModal from '@/components/organisms/ChangePlanModal.vue';
export default {
    props: ['user'],
    name: "SubscriptionsComponent",
    components: {
        InlineSvg,
        ChangePlanModal
    },
    data() {
        return {
            subscription: null,
            showCancelModal: false,
            openPlanModal: false,
        }
    },
    async mounted() {
        await this.getSubscriptions();
    },
    methods: {
        async getSubscriptions() {
            let response = await this.$axios.get('/accounts/' + this.$route.params.account_id + '/active-plan');
            this.subscription = response.data;
        },
        async cancelSubscription() {
            let response = await this.$axios.post('/accounts/' + this.$route.params.account_id + '/cancel-plan');
            await this.getSubscriptions();
            this.showCancelModal = false;
        }
    }
}
</script>