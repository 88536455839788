<template>
    <div class="account-details">
        <div class="account-details__details">
            <p class="account-details__details--title">Detaliile Contului</p>
            <div class="account-details__details-info">
                <img v-auth-image="$axios.defaults.baseURL+data.image" class="account-details__details-info--image" />
                <div class="account-details__details-info--text">
                    <p>{{this.data.name}}</p>
                    <p>{{this.data.email}}</p>
                    <!-- <p>Plan: <span>Individual Demo</span></p> -->
                </div>
            </div>
        </div>
        <div class="account-details__actions">
            <v-btn @click.stop="openEditModal = true" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                <span>Editare Detalii</span>
                <inline-svg src='/img/pencil.svg' width="18" height="18" />
            </v-btn>
            <v-btn @click.stop="openPassModal = true" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                <span>schimba parola</span>
                <v-icon color="#2e4dd4">mdi-lock-reset</v-icon>
            </v-btn>
            <!-- <v-btn @click.stop="openPlanModal = true" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                <span>schimba plan</span>
                <v-icon color="#2e4dd4">mdi-file-document-edit-outline</v-icon>
            </v-btn> -->
            <v-btn @click.stop="openDeleteModal = true" class="chartarium-button-hollow chartarium-button-hollow--primary" rounded elevation="0" color="#fff">
                <span>sterge cont</span>
                <v-icon color="#2e4dd4">mdi-delete</v-icon>
            </v-btn>
        </div>
        <account-edit-modal v-model="openEditModal" :btnIcon="'save'" :btnIconSize="24" :title="'Editare Cont'" :btnTitle="'Salveaza'" :selectedItem="data" :isAccount="true" @refreshUser="$emit('refreshUser')" />
        <change-pass-modal v-model="openPassModal" :mdi="true" :btnIcon="'mdi-lock-reset'" :btnIconSize="24" :title="'Schimba Parola'" :btnTitle="'schimba parola'" />
        <change-plan-modal v-model="openPlanModal" :btnIcon="'double-carret'" :btnIconSize="24" :title="'Schimbare Plan'" :btnTitle="'Schimba'" :selectedItem="data" />
        <delete-modal v-model="openDeleteModal" :mdi="true" :btnIcon="'mdi-delete'" :btnIconSize="24" :title="'Sterge Cont'" :btnTitle="'Sterge'" :selectedItem="data" :isUser="false" />
    </div>
</template>
<script>
import InlineSvg from 'vue-inline-svg';
import AccountEditModal from '../molecules/AccountEditModal.vue';
import ChangePassModal from '../molecules/ChangePassModal.vue';
import ChangePlanModal from '../organisms/ChangePlanModal.vue';
import DeleteModal from '../molecules/DeleteModal.vue';
export default {
    components: {
        InlineSvg,
        AccountEditModal,
        ChangePassModal,
        ChangePlanModal,
        DeleteModal
    },
    props: ['data'],
    data() {
        return {
            openEditModal: false,
            openPassModal: false,
            openPlanModal: false,
            openDeleteModal: false,
            avatar: null,
        }
    }
}
</script>