<template>
    <v-dialog
        class="app-modal"
        v-model="show"
        persistent
        max-width="1300"
    >
      <v-card class="app-modal__card">
            <div class="app-modal__card--close">
               <inline-svg @click.stop="close()" src="/img/modal-close.svg" height="24" width="24" fill="#2e4dd4"/>
            </div>
            <v-form ref="form">
                <div class="app-modal__card--content">
                    <h1 class="app-modal__title">{{title}}</h1>
                    <div class="app-modal__plan">

                       <button-toggle @activeToggle="visiblePlanType = $event"/>
                       <v-item-group 
                            class="app-modal__plan-items"
                            v-model="selectedPlan"
                            v-if="visiblePlanType === 1"
                        >
                            <div v-for="plan in individualPlans" :key="plan.id">
                                <v-item 
                                    v-slot="{ active, toggle }"
                                    :value="plan.id"
                                    ripple
                                >
                                    <div 
                                        
                                    :class="{ 'app-modal__plan-items-plan--active': active, 
                                              'current': plan.id == activeplan.id || plan.id == 2,

                                     }"
                                        class="app-modal__plan-items-plan"
                                        @click="toggle"
                                        ripple
                                    >
                                        <plan-card :active="(plan.id == activeplan.id)" :data="plan"/>
                                    </div>
                                </v-item>
                            </div>
                        </v-item-group>


                        <v-item-group 
                            class="app-modal__plan-items"
                            v-model="selectedPlan"
                            v-if="visiblePlanType === 2"
                        >
                            <div v-for="plan in organisationPlans" :key="plan.id">
                                <v-item 
                                    v-slot="{ active, toggle }"
                                    :value="plan.id"
                                    ripple
                                >
                                    <div 
                                        :class="active ? 'app-modal__plan-items-plan--active' : ''"
                                        class="app-modal__plan-items-plan"
                                        @click="toggle"
                                        ripple
                                    >
                                        <plan-card :data="plan"/>
                                    </div>
                                </v-item>
                            </div>
                        </v-item-group>
                    </div>
                </div>
            </v-form>
            <div class="app-modal__card--actions">
                <v-btn class="app-modal__btn" elevation="4" rounded color="#fff" @click.stop="changePlan">
                    <inline-svg :src="'/img/'+btnIcon+'.svg'" :height="btnIconSize" :width="btnIconSize" fill="#2e4dd4"/>
                    <span>{{btnTitle}}</span>
                </v-btn>
            </div>
      </v-card>
    </v-dialog>
</template>
<script>
import ButtonToggle from '../atoms/ButtonToggle.vue';
import PlanCard from '../molecules/PlanCard.vue';
import InlineSvg from 'vue-inline-svg';
import axios from 'axios';
export default {
    props:['title','btnIcon', 'btnIconSize','btnTitle','value','selectedItem', 'activeplan'],
    components:{
        InlineSvg,
        ButtonToggle,
        PlanCard
    },
    computed: {
        show: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        individualPlans: function() {
            
           return this.plans.filter((plan)=>plan.type==='personal');
        },
        organisationPlans: function() {
            
            return this.plans.filter((plan)=>plan.type==='organization');
        }
    },
    data() {
        return {
            visiblePlanType:null,
            selectedPlan:null,
            plans:[
            ]
        }
    },
    async mounted(){
        console.log('aici');
        await this.getPlans();
        console.log('acolo');
    },
    methods: {
        changePlan() {
            let redirect = '/admin/' + this.$route.params.account_id + '/checkout?planId=' + this.selectedPlan;
            this.$router.push(redirect);
        },
        async getPlans() {
            let response = await axios.get('https://api.chartarium.ro/api/open/plans');
            this.plans = response.data;
            console.log('plaans', this.plans);
        },
        close(){
           this.show = false;
        }, 
    }

}
</script>